#homepage {
	
	.background {
		background: url("../../assets/pics/background/img-Blog_FuhrparkABC_960x500.jpg");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		background-color: black;
	
		.background-black {
			background-color: #2b2f33;
			opacity: 0.9;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 65vh;

			@media (min-width: 991.98px) {
				height: 95vh;
			}
		}

		.trade {
			color: white;
			font-family: 'Roboto Mono', monospace;
			font-weight: 100;
			font-size: 1.4em;
			margin-top: 130px;


			@media (min-width: 767.98px) {
				margin-top: -60px;
				font-size: 2.125em;
			}	
			@media (min-width: 1440.98px) {
				font-size: 3.125em;
			}

			.up {
				color: #f8ab34;
			}

			div {
				display: inline;
			}
		}

		.header-bottom {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			align-content: center;
			align-items: flex-start;
			margin-bottom: 30px;
			
			@media (min-width: 767.98px) {
				display: flex;
				flex-direction: row;
				justify-content: space-around;
			}
				
			.div-informations {
				display: flex;
				justify-content: center;
				margin-top: 7px;

				.dashed {
					border: dashed 1px;
					width: 1.75em;
				    height: 1.75em;
				    border-radius: 3.75em;
				    color: #f8ab34;
				    position: relative; 

				    @media (min-width: 767.98px) {
				    	border: dashed 2px;
						width: 2.75em;
				    	height: 2.75em;
					}
					@media (min-width: 1440.98px) {
						width: 3.75em;
				    	height: 3.75em;
					}

				    i {
				    	color: white;
				    	position: absolute; 
				    	top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						font-size: 0.8em;

						@media (min-width: 767.98px) {
							font-size: 1.1em;
						}	
						@media (min-width: 1440.98px) {
							font-size: 1.5625em;
						}
				    }				
				}

				.informations {
					color: white;
					font-family: 'Roboto Condensed', sans-serif;
					font-weight: 300;
					font-size: 0.9em;	

					@media (min-width: 991.98px) {
						font-size: 1.1em;
					}	
					@media (min-width: 1440.98px) {
						font-size: 1.625em;
					}	

					a {
					    color: white;
					    text-decoration: none;
					    &:hover {
					      text-decoration: none;
					      color: white;
					    }
					}		
				}
			}
		}	
	}

	// gestion du corp du site entre l'image de landing et le footer 
	.background-europe {
		@media (min-width: 991.98px) {
			background: url("../../assets/pics/background/europe-vierge.png");
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 90% 100%;
		}	

		.mercup-presentation {
			.partie-voitures {
				.voiture-coupe {
					.voiture-1 {
						position: relative;
						max-width: 80%;
						left: -140px; 
						display: none;
						
						@media (min-width: 1199.98px) and (max-width: 1800px) {
							left: -195px; 
						}
						@media (min-width: 1800px) {
							left: -272px; 
						}
					}
				}
				 
				.voiture {
					
					// Texte+logo mercup 
					.mercup-adventure {
							display: flex;
							flex-direction: column;
							align-items: center ;
						
						.launch {
							font-size: 1.2em;
							font-family: 'Roboto Condensed', sans-serif;
							font-weight: 300;
							margin-bottom: -1%;
							margin-left: -55px;

							@media (min-width: 767.98px) {
								font-size: 1em;
								margin-left: -30px;
							}
							@media (min-width: 1199.98px) {
								margin-left: -83px;
							}
							@media (min-width: 1700px) {
								font-size: 1.688em;
							}
						}

						h2 {
							font-family: 'Roboto Condensed', sans-serif;
							font-weight: 300;
							font-size: 4.125em;

							@media (min-width: 767.98px) {
								font-size: 3.125em;
							}
							@media (min-width: 1199.98px) {
								font-size: 4.125em;
							}
							@media (min-width: 1700px) {
								font-size: 6.125em;
							}	

							.up {
								color: #f8ab34;
							}
						}

						.adventure {
							font-size: 1.2em;
							font-family: 'Roboto Condensed', sans-serif;
							font-weight: 300;
							margin-top: -20px;
							margin-left: 150px;

							@media (min-width: 767.98px) {
								margin-left: 100px;
								font-size: 1em;
							}
							@media (min-width: 1199.98px) {
								margin-left: 150px;
							}
							@media (min-width: 1700px) {
								font-size: 1.688em;
								margin-left: 220px;
							}
						}
					}

					// taille de la voiture
					.voiture-2 {
						max-width: 90%;

						@media(min-width: 767.98px) AND (max-width: 850px) {
							max-width: 120%;
						}
					}
				}
			}
			.partie-text {
				order: -1;

				@media(min-width: 991.98px){
					order:2;
				}
				//gestion du seul titre h1
				h1 {
					font-size: 1.4em;
					font-family: 'Roboto Condensed', sans-serif;
					font-weight: 300;
					margin-top: 30px !important;
					
					@media (min-width: 575.98px) {
						font-size: 2em;
					}
					@media (min-width: 991.98px) {
						font-size: 1.7em;
					}
					@media (min-width: 1299.98px) {
						font-size: 2.05em;
					}
					@media (min-width: 1399.98px) {
						font-size: 2.5em;
					}
					@media (min-width: 1399.98px) {
						font-size: 2.2em;
					}
					@media (min-width: 1699.98px) {
						font-size: 2.53em;
					}
					@media (min-width: 1799.98px) {
						font-size: 2.8em;
					}
					@media (min-width: 1899.98px) {
						font-size: 3em;
					}
					@media (min-width: 1999.98px) {
						font-size: 3.2em;
					}
					@media (min-width: 2099.98px) {
						font-size: 3.4em;
					}
					@media (min-width: 2199.98px) {
						font-size: 3.6em;
					}
					@media (min-width: 2299.98px) {
						font-size: 3.8em;
					}
					@media (min-width: 2399.98px) {
						font-size: 4em;
					}
					@media (min-width: 2499.98px) {
						font-size: 4.2em;
					}
					@media (min-width: 2599.98px) {
						font-size: 4.4em;
					}
				}
				.barre-jaune {
					border-bottom: #f8ab34 solid 2px;
					width: 30%; 
					display: inline-block;
				}

				p {
					font-size: 1.125em;
					font-family: 'Roboto Condensed', sans-serif;
					font-weight: 300;
					text-align: justify;
					line-height: 1.5;

					@media(max-width: 575.98px) {
						margin-left: 0px!important;
						margin-right: 0px!important;
					}
					@media (min-width: 767.98px) AND (max-width: 991.98px){
						margin-left: 100px;
						margin-right: 100px;
					}
					@media (min-width: 991.98px) {
						font-size: 1.125rem;
					}
					@media (min-width: 1199.98px) {
						font-size: 1.44em;
					}
					@media(min-width: 2200.98px) {
						font-size: 2.5em;
					}
				}
			}
		}

		.mini-titres {
			.mini-titre-regroup {
				.cercle-text {
					.cercle {
					    width: 75px;
					    height: 75px;
					    border-radius: 50px;
					    margin-bottom: -12%;
					    position: relative;
					    left: 50%;
					    transform: translate(-50%);

					    @media(min-width: 1191.98px) {
					    	width: 100px;
					    	height: 100px;
					    }

					    &:hover {
					    	opacity: 1;
						  	.barre-blanche {
							    opacity: 0;
			    			}
		    			}

		    			.barre-blanche {
		    				border-bottom: 20px solid white;
							display: inline-block;
							opacity: 0.69;
							z-index: 2;
							width: 74px;					   
						    position: absolute;
						    top: 95%;
						    left: 50%;
						    transform: translate(-50%, -50%);
						    transition: 0.5s;
					  	}
					}

					.orange {
						background-color: #f8ab34;
					    opacity: 0.54;
					    transition: 0.5s;
					    
					    i {
					    	color: white; 
					    	font-size: 25px; 
					    	font-weight: 100;
					    	position: absolute;
					    	top: 50%;
					    	left: 50%;
					    	transform: translate(-50%, -50%);
					    	
					    	@media(min-width: 1191.98px) {
					    		font-size: 55px; 
					    	}
					    }
					}

					.gris {
						background: #2b2f33;
						opacity: 0.54;
						transition: 0.5s;
					    
					    i {
					    	color: white; 
					    	font-size: 25px; 
					    	font-weight: 100;
					    	position: absolute;
					    	top: 50%;
					    	left: 50%;
					    	transform: translate(-50%, -50%);
					    	
					    	@media(min-width: 1191.98px) {
					    		font-size: 55px; 
					    	}
					    }
					}

					.mini-titre {
						font-size: 1.6em;
						font-family: 'Roboto Condensed', sans-serif;
						font-weight: 400;
						margin-left: -13.5%;
						transition: 0.3s;

						@media(min-width: 991.98px) {
					   		font-size: 1.2em;
						}
						@media(min-width: 1191.98px) {
					    	font-size: 1.85em;
					    	margin-left: -2.5%; 
					    }
					}
				}
				.mini-texte {
					font-size: 1.125em;
					font-family: 'Roboto Condensed', sans-serif;
					font-weight: 300;	
					text-align: center;
					position: relative;
					transition: 0.3s;
					
					@media(min-width: 991.98px) {
					    height: 150px;	
					    font-size: 0.9em;
					}
					@media(min-width: 1191.98px) {
						font-size: 1.125em;
					}
				}
			}
		}
	}

	// Gestion du formulaire de contact et le footer 
	.footer {
		position: relative;
		
		@media (min-width: 991.98px) {
			margin-top: 220px;
		}
		@media (min-width: 1199.98px) {
			margin-top: 160px;
		}

		.contact-form {
			background-color: white;
			border: 12px solid #f8ab34;
			border-radius: 50px;
			box-shadow: 4px 4px 14px #2b2f33;
			margin-bottom: 15px;
			width: 95%;

			@media (min-width: 991.98px) {
				width: 300px;
				position: absolute;
				top: -10%;
				left: 80%;
				transform: translate(-50%, -50%);
			}
			@media (min-width: 1399.98px) {
				width: 400px;
				position: absolute;
				top: 15%;
				left: 77%;
				transform: translate(-50%, -50%);
			}
		}
	}
}

