.footer-background {
	background-color: #2b2f33;
	width: 100vw;
	
	@media (max-width: 991.98px) {
		height: 200px;
		display: flex;
		align-content: space-around;
		justify-content: space-around;
	}

	.picto-slogan {
		margin-left: -2.4%;
		width: 20%;
		
		@media(min-width: 1199.98px) {
			margin-left: -1.7%;
		}
		@media(min-width: 1800px) {
			width: 15%;
		}
	}

	.picto-text {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		
		@media (min-width: 1199.98px) {
			margin-left: -20px;
		}
		.picto {
			width: 45px;
			height: 45px;
				
			@media(min-width: 1199.98px) {	
				width: 60px;
				height: 60px;
			}
		}

		p {
			color: white;
			font-size: 0.8em;
			font-family: 'Roboto Condensed', sans-serif;
			font-weight: 400;
			width: 220px !important;

			@media(min-width: 1440.98px) {
				font-size: 1.3em;
				width: 380px !important;
			}
		}

		.picto-text-1 {			
			
			@media(min-width: 991.98px) {	
				position: absolute;
				top: 30%;
				left: 41%;
				transform: translate(-50%, -50%);
			}
			@media(min-width: 1198.98px) {	
				position: absolute;
				top: 30%;
				left: 44%;
				transform: translate(-50%, -50%);
			}
		}

		.picto-text-2 {

			@media(min-width: 991.98px) {	
				position: absolute;
				top: 54%;
				left: 35%;
				transform: translate(-50%, -50%);
			}
		}
		
		.picto-text-3 {

			@media(min-width: 991.98px) {	
				position: absolute;
				top: 80%;
				left: 27%;
				transform: translate(-50%, -50%);
			}
		}
	}
}