//la navbar desktop
#navbar {
  display: none;
    
  @media(min-width: 767.89px) {
    display: flex;
    align-items: center;
    justify-content: space-between;  
  }

  .logo-img {
    max-height: 80px;
    padding-left: 20px;
    padding-top: 20px;
    
    @media(min-width: 1799.89px) {
      max-height: 150px;
    }
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    
    .link {
      color: white;
      text-decoration: none; 
      padding-right: 50px;
      font-size: 0.9em;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 400;
      display: flex;
      transition: 0.1s;          
      
      @media (min-width: 1440.98px) {
        font-size: 1.25em;
      } 

      &:hover {
        opacity: 0.7; 
      }
    } 
  }
}

//le menu hambourger
.hambourger {
  position: fixed; 
  width: 100vw; 
  background-color: #2b2f33; 
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    .logo-img {
      max-height: 60px;
    }
  }

  div {
    #menu_burger {
      color: #f8ab34; 
      font-size: 30px; 
      font-weight: 200;
    }
  }
}

#mobile_menu {
  width: 250px;
  left: -250px;
  height: 100%;
  background-color: #2b2f33;
  border-right: 2px solid #f8ab34;
  position: fixed;
  padding-top: 20px;
  transition-duration: 1s;
  z-index: 1007;
  
  @media (min-width: 575.98px) {
    width: 275px;
    left: -275px;
  }
  @media (min-width: 767.98px) {
    width: 370px;
    left: -370px;
  }


  .logo {
    .alogo{
      img {
        width: 80%;
        max-height: 50px;
      }
    }
  }
  
  .seperation {
    width: 80%;
    border-bottom: 1px solid white;
    display: inline-block;
  }
  .elements-menu {
    .burgerlink {
      text-decoration: underline;
      color: white;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 400;
      font-size: 0.9em;
      &:hover {
        text-decoration: none;
        color: grey;
      }
    }
  } 
}


#mobile_menu_background {
  transition-duration: 1s;
  left: -100%;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #2b2f33;
  opacity: 0.7;
  z-index: 1006;
  div {
    div {
      width: 20px;
      font-size: 20px;

      @media (min-width: 400px) {
        font-size: 24px;
      } 
      
      #menu_burger_close {
        color: #f8ab34;
        font-size: 30px; 
        font-weight: 200;
      }
    }
  }
}


