h2, .thanks {
	font-size: 1.75em;
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 400;
}
input, textarea {
	font-size: 1em;
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 300;
	background-color: #f7f7f7 !important;
	opacity: 0.9;
	
}

.labels {
	font-size: 1em;
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 300;

}
.checkboxes:checked {
	font-size: 1.2em;
	background-color: #f8ab34 !important;
}

.interested, .mail-sent {
	font-size: 1.25em;
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 300;
}

.bouton {
	font-size: 1.25em !important;
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: 300;
	color: white;
	background-color: #2b2f33;
	width: 200px;
	border-radius: 10px;
}